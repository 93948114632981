@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

* {
	margin: 0;
	padding: 0;
	border: none;
	outline: none;
	font-family: 'Roboto', sans-serif;
}

html {
	overflow-x: hidden;
}

#root {
	height: 100vh;
	display: flex;
	flex-direction: column;
}

a {
	text-decoration: none;
}

p {
	margin-top: 8px;
	text-indent: 20px;
}